import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import { Link, navigate } from "gatsby"
import CtaButton from "../components/cta-button/cta-button"
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./dead-ends.scss"
import { ServiceCard } from "../components/service-card/service-card"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { GlobalStateProvider } from "../utils/globalStateProvider"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"
export default function NegligenciaSinPerjucioEconomico() {
  return (
    <GlobalStateProvider>
      <SEO title="Sin perjucio economico" />
      <Layout pageId="deadend-without-economic-damage">
        <div className="deadend-container">
          <h1>Resultado del cuestionario</h1>
          <h2>¡Puede reclamar aunque no haya perjucio económico!</h2>
          <p>
            Hay casos donde se puede{" "}
            <Link to="/que-hacer-cuando-un-abogado-no-cumple-con-su-trabajo/">
              conseguir abrir un expediente informativo y/o disciplinario al
              abogado
            </Link>{" "}
            por el Colegio de Abogados que corresponda. Desde{" "}
            <strong>negligenciasdeabogados.net</strong> te podemos ayudar:
          </p>
          <center className="button-container">
            <ServiceCard
              title="Te llamamos 30"
              key="consulta-experto"
              featuredImage={
                <FontAwesomeIcon icon={faWhatsapp} color="#fcc831" size="2x" />
              }
              featureList={[
                "Servicio <strong>premium</strong>",
                "Un <strong>experto</strong> te llamará (tfno/whatsapp)",
                // "<strong>30 minutos</strong> por <strong class='price'>30€</strong>",
              ]}
              ctaLink="/consulta-experto"
            />
          </center>

          <p>
            Si consideras que hay que corregir algo en las respuestas del
            cuestionario de autovaloración,{" "}
            <Link to="/cuestionario-autovaloracion/">prueba de nuevo</Link>
          </p>
        </div>
        <SloganFlipper></SloganFlipper>
        <FullScreenModal />
      </Layout>
    </GlobalStateProvider>
  )
}
